import localee2e93302 from '../..\\assets\\locales\\nl-NL.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"nl","messages":{"nl":"~assets/locales/nl-NL.json"}},
  vueI18nLoader: false,
  locales: [{"code":"nl","name":"Nederlands","file":"nl-NL.json"}],
  defaultLocale: "nl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "C:\\laragon\\www\\gasservicewillemsen\\assets\\locales\\",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {"index":{"nl":"/"},"about-us":{"nl":"/over-ons"},"contact":{"nl":"/contact"},"products":{"nl":"/producten"},"faq":{"nl":"/faq"},"service/index":{"nl":"/service"},"service/_slug":{"nl":"/service/:slug"}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"nl","name":"Nederlands","file":"nl-NL.json"}],
  localeCodes: ["nl"],
}

export const localeMessages = {
  'nl-NL.json': () => Promise.resolve(localee2e93302),
}
