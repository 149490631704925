import wordpress from '~/service/wordpress'

export const strict = false

export const state = () => ({
  footer: {}
})

export const getters = {
  getFooter(state) {
    return state.footer
  }
}

export const mutations = {
  SET_FOOTER(state, footer) {
    state.footer = footer
  }
}

export const actions = {
  async nuxtServerInit({ commit }) {
    const footer = await wordpress.get(`page/footer`)

    commit('SET_FOOTER', footer.data)
  }
}
