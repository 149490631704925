import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _af22a450 = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages/contact" */))
const _f21d8c64 = () => interopDefault(import('..\\pages\\faq.vue' /* webpackChunkName: "pages/faq" */))
const _3d0a4554 = () => interopDefault(import('..\\pages\\about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _59602edc = () => interopDefault(import('..\\pages\\products.vue' /* webpackChunkName: "pages/products" */))
const _6b7441f5 = () => interopDefault(import('..\\pages\\service\\index.vue' /* webpackChunkName: "pages/service/index" */))
const _40ec518a = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _69bda0ad = () => interopDefault(import('..\\pages\\service\\_slug.vue' /* webpackChunkName: "pages/service/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _af22a450,
    name: "contact___nl"
  }, {
    path: "/faq",
    component: _f21d8c64,
    name: "faq___nl"
  }, {
    path: "/over-ons",
    component: _3d0a4554,
    name: "about-us___nl"
  }, {
    path: "/producten",
    component: _59602edc,
    name: "products___nl"
  }, {
    path: "/service",
    component: _6b7441f5,
    name: "service___nl"
  }, {
    path: "/",
    component: _40ec518a,
    name: "index___nl"
  }, {
    path: "/service/:slug",
    component: _69bda0ad,
    name: "service-slug___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
